import React from 'react'
import { Grid } from '@farewill/ui'

import { ReactNode } from 'react-markdown'
import Hero, { HERO_VARIANTS, HeroProps } from 'components/Hero'

export type HeroWithImageProps = HeroProps & {
  image: ReactNode
}

const HeroWithImage = ({
  title,
  titleMaxWidth,
  subtitle,
  additionalContent,
  additionalGridItems,
  image,
  variant = HERO_VARIANTS.RIGHT,
}: HeroWithImageProps): React.ReactElement => {
  const isCenteredVariant = variant === HERO_VARIANTS.CENTERED
  return (
    <Hero
      title={title}
      titleMaxWidth={titleMaxWidth}
      subtitle={subtitle}
      additionalContent={additionalContent}
      additionalGridItems={(
        [
          <Grid.Item
            key="image"
            spanFromM={isCenteredVariant ? 10 : 6}
            spanFromL={6}
            startColumn={1}
            startColumnFromM={isCenteredVariant ? 2 : 7}
            startColumnFromL={isCenteredVariant ? 4 : 7}
            startRow={isCenteredVariant ? 1 : 2}
            startRowFromM={1}
            centered
          >
            {image}
          </Grid.Item>,
        ] as ReactNode[]
      )
        .concat(additionalGridItems)
        .filter((item) => !!item)}
      variant={variant}
    />
  )
}

export default HeroWithImage
