import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Wrapper, Divider } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import DefaultLayout from 'layouts/DefaultLayout'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'
import { PRODUCTS } from 'lib/products/constants'
import { TRUSTPILOT_RATING_OUT_OF_5, TRUSTPILOT_REVIEWS_TOTAL } from 'config'
import TrustpilotReviews from 'components/TrustpilotWidget/TrustpilotReviews'
import AwardBanner from 'components/AwardBanner'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'
import Charities from 'views/wills/components/Charities'

import Hero from './Hero'
import Features from './Features'
import Steps from './Steps'
import Legal from './Legal'
import GetStarted from './GetStarted'

const TelephoneWillsPage = (): React.ReactElement => {
  const { faqSection } = useStaticQuery<{
    faqSection: GatsbyTypes.ContentfulFaqSection
  }>(graphql`
    query {
      faqSection: contentfulFaqSection(
        contentful_id: { eq: "4Det8GCxBp608F6HcRAJv7" }
      ) {
        ...FaqSectionFields
      }
    }
  `)

  return (
    <DefaultLayout
      title="Telephone wills | Write a will from the comfort of your own home"
      description={`Make a will over the phone and get it delivered to your door with the UK’s number one will specialists. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
        TRUSTPILOT_REVIEWS_TOTAL
      )} customers on Trustpilot.`}
      headerColor={COLOR.WHITE}
      product={PRODUCTS.TELEPHONE_WILLS}
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
    >
      <Wrapper background={COLOR.WHITE}>
        <Hero />

        <Divider container />

        <Wrapper container containerPaddingBottom="XL">
          <TrustpilotReviews showTitle />
        </Wrapper>

        <Divider />
        <AwardBanner />

        <Features />
      </Wrapper>
      <Divider />

      <Steps />
      <Divider />
      <Wrapper background={COLOR.WHITE}>
        <Legal />

        <Divider container />

        <Wrapper container>
          <FrequentlyAskedQuestions
            title={faqSection.title}
            questions={faqSection.questions}
            selectedProduct={PRODUCTS.TELEPHONE_WILLS}
          />
        </Wrapper>
        <Divider container />

        <Wrapper container>
          <Charities />
        </Wrapper>
      </Wrapper>

      <GetStarted />
    </DefaultLayout>
  )
}

export default TelephoneWillsPage
